import { Dialog } from '~/components/ui/dialog.tsx'
import { Button } from '~/components/ui/inputs/button/Button.tsx'
import { Spacer } from '../ui/spacer.tsx'
import { type Video } from '@prisma/client'

type Props = {
  presentation: Video
  open: boolean
  onOpenChange: (open: boolean) => void
}
export default function ViewPresentationDialog({
  presentation,
  open,
  onOpenChange,
}: Props) {
  return (
    <Dialog open={open} onClose={() => onOpenChange(false)}>
      <iframe
        width="100%"
        height="415"
        src={presentation.url}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <Spacer size="4xs" />
      <div className="flex justify-center">
        <Button variant={'cancel'} onClick={() => onOpenChange(false)}>
          Volver
        </Button>
      </div>
    </Dialog>
  )
}
