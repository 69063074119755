// This is called a "splat route" and as it's in the root `/app/routes/`
// directory, it's a catchall. If no other routes match, this one will and we
// can know that the user is hitting a URL that doesn't exist. By throwing a
// 404 from the loader, we can force the error boundary to render which will
// ensure the user gets the right status code and we can display a nicer error
// message for them than the Remix and/or browser default.

import { type DataFunctionArgs, json } from '@remix-run/node'
import { Outlet, useLoaderData } from '@remix-run/react'
import { ActionTypeEnum, StatusEnum } from 'types/enums.ts'
import { GeneralErrorBoundary } from '~/components/error-boundary.tsx'
import { ProfessionalProfile } from '~/features/home/professional-profile.tsx'
import { MainContainer } from '~/components/layout/main-container.tsx'
import { MainLayout } from '~/components/layout/main-layout.tsx'
import {
  getUserId,
  getUserRole,
  requireUserId,
} from '~/features/auth/auth.server.ts'
import { prisma } from '~/utils/config/db.server.ts'
import { redirectWithToast } from '~/features/toast/flash-session.server.ts'
import { invariantResponse } from '~/utils/misc.tsx'
import { getCurrencyValue } from '~/features/currency/getCurrencyValue.server.ts'
import { getStarsAndReviewDataForProfesional } from '~/features/reviews/getStarsAndReviewDataForProfesional.tsx'

//Added other properties in the slugType variable
export const SlugType = {
  'PROFESSIONAL-PROFILE': 'professional-profile',
  SERVICES: 'services',
  PROFESSIONALS: 'professionals',
} as const

const getProfessionalData = async (
  slug: string,
  request: DataFunctionArgs['request'],
) => {
  const userId = await getUserId(request)
  const userRole = await getUserRole(request)
  const professional = await prisma.user.findUnique({
    where: {
      slug,
      OR: [
        {
          status: StatusEnum.ACTIVE,
        },
        // even if professional is not active, the professional can see his own profile
        {
          id: Number(userId),
        },
      ],
    },
    select: {
      status: true,
      nickname: true,
      myResume: true,
      slug: true,
      services: {
        include: {
          serviceBooked: {
            include: {
              review: true,
            },
          },
          category: {
            select: {
              isEnabled: true,
            },
          },
        },
      },
      expertise: true,
      favoritedBy: {
        select: {
          id: true,
        },
      },
      languages: true,
      video: true,
      avatarImage: { select: { id: true } },
    },
  })

  invariantResponse(professional, 'User not found', { status: 404 })
  invariantResponse(
    professional.status != StatusEnum.PENDING,
    'User not found',
    { status: 404 },
  )

  const { stars, reviews } = getStarsAndReviewDataForProfesional(professional)

  reviews.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())

  const reviewersIds = reviews.map(review => review.reviewerId)

  const reviewers = await prisma.user.findMany({
    where: {
      id: {
        in: reviewersIds,
      },
    },
    select: {
      id: true,
      firstName: true,
    },
  })

  const isFavorite =
    userId &&
    professional.favoritedBy.find(
      (favorited: { id: number }) => favorited.id === userId,
    )

  const { value, name } = await getCurrencyValue({ request, userId })

  return json({
    professional,
    stars,
    reviews,
    reviewers,
    isFavorite,
    type: SlugType['PROFESSIONAL-PROFILE'],
    userRole,
    currency: { value, name },
  })
}

export async function loader({ params, request }: DataFunctionArgs) {
  const slug = String(params.rootSlug)

  const urlParts = request.url.split('/')
  if (urlParts[urlParts.length - 1].includes('dar-valoracion')) {
    const userRole = await getUserRole(request)
    return json({ userRole })
  }

  const professional = await prisma.user.findFirst({
    where: { slug },
  })
  if (professional) {
    return getProfessionalData(slug, request)
  }

  throw new Response('Not found', { status: 404 })
}

export async function action({ request, params }: DataFunctionArgs) {
  const userId = await requireUserId(request, 'user')
  const slug = params.rootSlug

  invariantResponse(slug, 'Professional not found', { status: 404 })

  const formData = await request.formData()
  const type = formData.get('type')?.toString()

  if (type === ActionTypeEnum.delete) {
    const nickname = formData.get('id')!.toString()
    await prisma.user.update({
      where: {
        id: userId,
      },
      data: {
        favorites: {
          disconnect: {
            nickname,
          },
        },
      },
    })

    return redirectWithToast(``, {
      title: 'Favorito eliminado',
      description: `${nickname} ha sido eliminado de tu lista de favoritos`,
    })
  }

  if (type === ActionTypeEnum.edit) {
    const { nickname } = await prisma.user.update({
      where: {
        id: userId,
      },
      data: {
        favorites: {
          connect: {
            slug,
          },
        },
      },
      select: {
        nickname: true,
      },
    })

    return redirectWithToast(``, {
      title: 'Favorito añadido',
      description: `${nickname} ha sido añadido a tu lista de favoritos`,
    })
  }

  return json({})
}

export default function DefaultRoute() {
  const data: any = useLoaderData()

  return (
    <MainLayout userRole={data.userRole}>
      {data.professional ? (
        <MainContainer>
          <ProfessionalProfile
            professional={data.professional}
            stars={data.stars}
            reviews={data.reviews}
            reviewers={data.reviewers}
            isFavorite={data.isFavorite}
            currency={data.currency}
          />
        </MainContainer>
      ) : (
        <Outlet />
      )}
    </MainLayout>
  )
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />
}
