import { getUserImgSrc } from '../../utils/misc.tsx'
import { Spacer } from '~/components/ui/spacer.tsx'
import { Card } from '~/components/ui/card.tsx'
import { Button } from '~/components/ui/inputs/button/Button.tsx'
import { StarsRating } from '~/components/ui/stars.tsx'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '~/components/ui/tabs.tsx'
import { ServiceCard } from '~/components/cards/service-card.tsx'
import { ScrollArea } from '~/components/ui/scroll-area.tsx'
import { ReviewCard } from '~/features/reviews/review-card.tsx'
import { Icon } from '~/components/ui/icons/icon.tsx'
import { VideoCard } from '~/components/cards/video-card.tsx'
import { useState } from 'react'
import { Form, Link, Outlet, useLocation } from '@remix-run/react'
import { type ReviewWithReviewerId } from '~/features/reviews/getStarsAndReviewDataForProfesional.tsx'
import {
  type Category,
  type Language,
  type Service,
  type Review,
  type User,
  type Video,
  type Session,
  type UserImage,
  type Currency,
} from '@prisma/client'
import { formatDecimals } from '~/utils/strings.ts'
import { ActionTypeEnum } from '../../../types/enums.ts'
import { ActionDelete } from '~/features/admin/action-delete.tsx'
import ViewPresentationDialog from '~/components/dialogs/view-presentation-dialog.tsx'

export interface FullProfessional extends User {
  services: ServiceWithSessionAndReview[]
  avatarImage?: UserImage
  expertise: Category[]
  favoritedBy: User[]
  languages: Language[]
  video: Video[]
}

export interface ServiceWithSessionAndReview extends Service {
  serviceBooked: SessionWithReview[]
  category: {
    isEnabled: boolean
  }
}

export interface SessionWithReview extends Session {
  review?: Review
}

type Props = {
  professional: FullProfessional
  stars: number
  reviews: ReviewWithReviewerId[]
  reviewers: User[]
  isFavorite: 0 | User
  currency: {
    name: Currency['name']
    value: Currency['value']
  }
}

export const ProfessionalProfile = ({
  professional,
  stars,
  reviews,
  reviewers,
  isFavorite,
  currency,
}: Props) => {
  const location = useLocation()

  const lastRoute = location.search
    .replace('?from=', '')
    .replace('&stars', '')
    .replace('&', '?')

  const isFromStars: boolean = location.search.includes('&stars')

  const activeServices = professional.services
    .filter(
      service =>
        service.status === 'active' && service.category.isEnabled === true,
    )
    .sort((a, b) => {
      if (a.shownPosition === null || b.shownPosition === null) {
        return 0
      }
      return a.shownPosition - b.shownPosition
    })

  const [showPresentation, setShowPresentation] = useState(false)

  let languages = ''
  professional.languages.forEach((language, i) => {
    languages += language.name

    if (professional.languages.length > i + 1) {
      languages += ', '
    }
  })

  const presentation = professional.video.find(video => video.isPresentation)

  return (
    <div>
      <Link
        to={`/${lastRoute}`}
        className="font-semibold text-foreground-muted"
      >
        {`< Volver a Inicio`}
      </Link>

      <Spacer size="4xs" />
      <Card className="p-6">
        <div className="flex flex-col gap-8 lg:flex-row">
          <div className="flex flex-col items-center gap-6">
            <img
              className="h-[100px] w-[100px] rounded-full object-cover"
              alt={`${professional.nickname}`}
              src={getUserImgSrc(professional.avatarImage?.id)}
            />
            <Form method="POST">
              {isFavorite ? (
                <ActionDelete
                  id={`${professional.nickname}`}
                  trigger={
                    <Button
                      className="whitespace-nowrap border-secondary font-normal text-white"
                      variant={'orange'}
                    >
                      <Icon
                        name="heart-filled"
                        size="drawer"
                        className="text-white"
                      >
                        <span>Eliminar de favoritos</span>
                      </Icon>
                    </Button>
                  }
                  description={`Confirma que desea remover "${professional.nickname}" de sus favoritos?`}
                />
              ) : (
                <Button
                  className="whitespace-nowrap"
                  variant={'outline-secondary'}
                >
                  <input
                    name="type"
                    type="hidden"
                    value={ActionTypeEnum.edit}
                  />
                  <Icon name="heart" size="drawer" className="text-secondary">
                    <span>Añadir a favoritos</span>
                  </Icon>
                </Button>
              )}
            </Form>
          </div>
          <div className="flex w-full flex-col gap-2">
            <div className="flex items-center justify-between">
              <h2 className="text-h5">{professional.nickname}</h2>
              {presentation && (
                <div>
                  <Button
                    size={'sm'}
                    className="whitespace-nowrap"
                    variant={'cancel'}
                    onClick={() => setShowPresentation(true)}
                  >
                    <Icon name="play-circle" size="drawer">
                      Ver presentación
                    </Icon>
                  </Button>

                  <ViewPresentationDialog
                    presentation={presentation}
                    open={showPresentation}
                    onOpenChange={isOpen => setShowPresentation(isOpen)}
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-1 sm:flex-row">
              <p className="text-primary">Calificación promedio: </p>
              <div className="flex gap-1">
                <StarsRating value={stars} />
                <span>({formatDecimals(stars, 1)})</span>
                <span>{reviews.length} Valoraciones</span>
              </div>
            </div>
            <div className="flex gap-1">
              <p className="text-primary">Idiomas: </p>
              <p className="text-secondary">{languages}</p>
            </div>
            <Spacer size="5xs" />
            <ScrollArea className="max-h-[200px] overflow-auto text-foreground-muted">
              {professional.myResume}
            </ScrollArea>
          </div>
        </div>
        <Spacer size="3xs" />
        <Tabs
          className="w-full"
          defaultValue={!isFromStars ? 'services' : 'reviews'}
        >
          <div className="w-full overflow-auto">
            <TabsList className="mb-8 min-w-[320px] gap-4">
              <TabsTrigger value="services">
                Mis servicios ({activeServices.length})
              </TabsTrigger>
              <TabsTrigger value="reviews">
                Valoraciones ({reviews.length})
              </TabsTrigger>
              <TabsTrigger value="videos">
                Videos (
                {presentation
                  ? professional.video.length - 1
                  : professional.video.length}
                )
              </TabsTrigger>
            </TabsList>
          </div>
          <TabsContent value="services">
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
              {activeServices.map((service, index) => (
                <ServiceCard
                  key={service.id}
                  professional={professional}
                  service={service}
                  currency={currency}
                  variant="public"
                  position={{
                    isFirst: index === 0,
                    isLast: index === activeServices.length - 1,
                  }}
                  params={location.search}
                />
              ))}
            </div>
          </TabsContent>
          <TabsContent value="reviews">
            <div className="flex flex-col gap-6">
              {reviews.map((reviews: ReviewWithReviewerId) => (
                <ReviewCard
                  key={reviews.id}
                  review={reviews}
                  user={
                    reviewers.find(
                      (reviewer: User) => reviewer.id === reviews.reviewerId,
                    ) as any
                  }
                />
              ))}
            </div>
          </TabsContent>
          <TabsContent value="videos">
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 2xl:grid-cols-3">
              {professional.video
                .sort(
                  (a, b) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime(),
                )
                .flatMap(video => {
                  if (video.isPresentation) {
                    return []
                  }
                  return <VideoCard key={video.id} video={video} />
                })}
            </div>
          </TabsContent>
        </Tabs>
      </Card>
      <Outlet />
    </div>
  )
}
